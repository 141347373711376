import React, { useCallback, useEffect, useState } from "react";

import PhoneInput from "react-phone-number-input";
import { useDispatch, useSelector } from "react-redux";

import { Search } from "@mui/icons-material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

import { Loader } from "../../common/loader";
import ViewEditUserPage from "./ViewEditUserPage";
import AlertDialog from "../../common/AlertDialog";
import PropertyUsersListTable from "./PropertyUsersListTable";
import CustomDateRangePicker from "../../common/CustomDateRangePicker";
import {
  decryptPayload,
  encryptPayload,
  getDateInYYYYMMDDFormat,
  getObjLength,
} from "../../helpers/utils";
import {
  mobileFilterResetBtnStyle,
  mobileFilterResultStyle,
} from "../AddLeads";
import {
  COUNTRY_CODE,
  jsonLastStepOptions,
  PER_PAGE,
  ROLES,
} from "../../helpers/constants";
import { propertyUsersAction } from "../../../redux/slices/admin/propertyUsers/propertyUsersSlice";

import "./propertyUsers.scss";
import { otherProvidersAction } from "../../../redux/slices/admin/other-providers/otherProvidersSlice";
import { countyRecordersAction } from "../../../redux/slices/admin/county-recorder/countyRecorderSlice";

const columns = [
  { key: 1, disablePadding: true, id: "no", label: "No.", minWidth: 50 },
  { key: 2, id: "name", disablePadding: true, label: "Name", minWidth: 115 },
  {
    key: 3,
    id: "email",
    disablePadding: true,
    label: "Username",
    minWidth: 250,
  },
  {
    key: 4,
    id: "phone",
    disablePadding: true,
    label: "Phone Number",
    minWidth: 155,
  },
  { key: 5, id: "type", disablePadding: true, label: "Type", minWidth: 110 },
  {
    key: 6,
    id: "properties",
    disablePadding: true,
    label: "Properties",
    minWidth: 110,
  },
  {
    key: 7,
    id: "created_at",
    disablePadding: true,
    label: "Created",
    minWidth: 120,
    sorting: true,
    defaultValue: "desc",
  },
  {
    key: 8, // set this key as default for sorting
    id: "updated_at",
    disablePadding: true,
    label: "Modified",
    minWidth: 120,
    sorting: true,
    defaultValue: "desc",
  },
  // {
  //   id: "notes",
  //   disablePadding: true,
  //   label: "Notes",
  //   minWidth: 80,
  // },
  // {
  //   id: "referral_user",
  //   disablePadding: true,
  //   label: "Referral User",
  //   minWidth: 120,
  // },
  // {
  //   id: "promo_code",
  //   disablePadding: true,
  //   label: "Promo Code",
  //   minWidth: 120,
  // },
  // {
  //   id: "json_last_step",
  //   disablePadding: true,
  //   label: "Last Step",
  //   minWidth: 140,
  // },
  // {
  //   id: "created_by",
  //   disablePadding: true,
  //   label: "Created By",
  //   minWidth: 130,
  // },
  {
    key: 9,
    id: "actions",
    disablePadding: true,
    label: "Actions",
    minWidth: 50,
  },
];

const filterUserType = [
  {
    label: "Customer",
    value: "customer",
  },
  {
    label: "Sub User",
    value: "sub_user",
  },
  {
    label: "Professional",
    value: "professional",
  },
  {
    label: "Service Provider",
    value: "service_provider",
  },
];

const PropertyUsersIndex = () => {
  const queryString = window.location.pathname;
  const userId = queryString.split("/")?.[2];
  console.log(parseInt(userId), "url val== >>", queryString);
  const queryString2 = window.location.search;
  let paramString = queryString2?.split("?")[1]?.replace("from=", "");
  let paramString2 = paramString?.split("&");
  const redirect_from = paramString2?.[0];
  const user_id = paramString2?.[1]?.replace("user_id=", "");
  console.log(redirect_from, "Param==>", user_id);

  const dispatch = useDispatch();
  const [alertMsg, setAlertMsg] = useState(false);
  const [editAlert, setEditAlert] = useState(false);
  const [successMsg, setSuccessMsg] = useState();
  const [errMsg, setErrMsg] = useState();
  const [toggleFilterOptions, setToggleFilterOptions] = useState(false);
  const [allTypes, setAllTypes] = useState("all");
  const [domainName, setDomainName] = useState();
  const [jsonLastStep, setJsonLastStep] = useState("");
  const [callApi, setCallApi] = useState(false);
  const [value, setValue] = useState("");
  const [payload, setPayload] = useState({});
  const [isViewEditUserScreenOpen, setIsViewEditUserScreenOpen] =
    useState(false);
  const [selectedRow, setSelectedRow] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [page, setPage] = useState(1);
  const [selectedDateRange, setSelectedDateRange] = useState(null);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [directionFlow, setDirectionFlow] = useState(true);
  const [selectedColumn, setSelectedColumn] = useState(8); // updated_at key for sorting
  const [selectedDateType, setSelectedDateType] = useState("created_at");
  const [selectedStatusType, setSelectedStatusType] = useState("");

  const {
    propertyUsersLoader,
    propertyUsersData,
    propertyUsersList,
    historyNoteLoader,
  } = useSelector((state) => state?.propertyUsers);

  const { domainListDataList } = useSelector((state) => state?.otherProviders);
  const { registeredCountyListData } = useSelector(
    (state) => state.countyRecorders
  );

  const onSuccess = () => {
    resetFilterStates();
    setAlertMsg(false);
    dispatch(propertyUsersAction.clearData({}));
    setEditAlert(true);
    getPropertyUserListingData();
  };
  const pageChangeHandler = (e, p) => {
    setPage(p);
    setPayload((prevState) => ({ ...prevState, offset: (p - 1) * PER_PAGE }));
  };

  const filterOptions = () => {
    if (
      value?.length > 0 ||
      selectedUserType ||
      selectedDateRange ||
      selectedStatusType ||
      domainName
    ) {
      localStorage.removeItem("offset");
      setCallApi(true);
      setPage(1);
      setPayload((prevState) => ({
        ...prevState,
        type: allTypes || undefined,
        value: value || undefined,
        from_date:
          (allTypes === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
            : undefined,
        to_date:
          (allTypes === "created_at" || selectedDateType === "created_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        modified_from_date:
          allTypes === "updated_at" || selectedDateType === "updated_at"
            ? selectedDateRange
              ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.startDate))
              : undefined
            : undefined,
        modified_to_date:
          (allTypes === "updated_at" || selectedDateType === "updated_at") &&
          selectedDateRange
            ? getDateInYYYYMMDDFormat(new Date(selectedDateRange?.endDate))
            : undefined,
        user_type: selectedUserType || undefined,
        domain: domainName || undefined,
        status: selectedStatusType || undefined,
        limit: PER_PAGE,
        offset: 0,
      }));
    }
  };

  const resetData = () => {
    localStorage.removeItem("offset");
    setAllTypes("all");
    setValue("");
    setDomainName("");
    setSelectedDateRange(null);
    setSelectedUserType("");
    setSelectedDateType("created_at");
    setSelectedStatusType("");
    if (callApi) {
      setPayload({ limit: PER_PAGE, offset: 0 });
      setCallApi(false);
      setPage(1);
      setDirectionFlow(true);
      setSelectedColumn(8);
    }
  };

  const resetFilterStates = () => {
    console.log("Resetting filter states");
    setAllTypes("all");
    setDomainName("");
    setValue("");
    setSelectedUserType("");
    setSelectedDateType("created_at");
    setSelectedStatusType("");
  };

  const clearLocalStorageData = () => {
    localStorage.removeItem("param");
    localStorage.removeItem("fixParams");
    localStorage.removeItem("searched_Add");
    localStorage.removeItem("searched_Add_popup");
  };

  // get listing of the property user
  const getPropertyUserListingData = useCallback(
    (removeOffset) => {
      let FilteredData =
        JSON.parse(decryptPayload(localStorage.getItem("offset"))) || 0;
      let URL = "";
      if (userId?.length > 0) {
        URL = `admin/users-v1/${userId}`;
      } else {
        URL = `admin/users-v1`;
      }
      dispatch(
        propertyUsersAction.getPropertyUsersData({
          url: URL,
          data: {
            type: FilteredData?.type || payload?.type,
            value: FilteredData?.value || payload?.value,
            from_date: FilteredData?.from_date || payload?.from_date,
            to_date: FilteredData?.to_date || payload?.to_date,
            domain: FilteredData?.domain || payload?.domain,
            modified_from_date:
              FilteredData?.modified_from_date || payload?.modified_from_date,
            modified_to_date:
              FilteredData?.modified_to_date || payload?.modified_to_date,
            user_type: FilteredData?.user_type || payload?.user_type,
            limit: PER_PAGE,
            offset: FilteredData?.offset || payload?.offset || 0,
            status: FilteredData?.status || payload?.status,
            order_by_column:
              FilteredData?.order_by_column ||
              payload?.order_by_column ||
              "updated_at",
            sorting_order:
              FilteredData?.sorting_order || payload?.sorting_order || "desc",
          },
          token: true,
        })
      );
      // for display Tab UI whenever admin redirect from provider or logs module
      if (removeOffset) {
        setAllTypes(FilteredData?.type || "all");
        setValue(FilteredData?.value || "");
        setDomainName(FilteredData?.domain);
        if (FilteredData?.from_date && FilteredData?.to_date) {
          setSelectedDateRange({
            startDate: FilteredData?.from_date,
            endDate: FilteredData?.to_date,
          });
        } else {
          setSelectedDateRange(null);
        }
        setSelectedUserType(FilteredData?.user_type || "");
        setSelectedDateType("created_at");
        setSelectedStatusType(FilteredData?.status || "");
        setJsonLastStep(FilteredData?.value || "");
        setCallApi(true);
        setPayload(FilteredData);
        if (Object.keys(FilteredData).length > 2) {
          setToggleFilterOptions(true);
        } else {
          setToggleFilterOptions(false);
        }
        localStorage.removeItem("offset");
      }
      if (FilteredData?.offset) {
        setPage(parseInt(FilteredData?.offset) / PER_PAGE + 1);
      }
    },
    [
      dispatch,
      payload?.from_date,
      payload?.modified_from_date,
      payload?.offset,
      payload?.modified_to_date,
      payload?.domain,
      payload?.to_date,
      payload?.type,
      payload?.user_type,
      payload?.value,
      payload?.order_by_column,
      payload?.sorting_order,
      payload?.status,
      userId,
    ]
  );

  // handle view or edit user button
  const handleViewEditUser = (row, isPropertyTab = false) => {
    if (isPropertyTab) {
      setActiveTab(2);
    } else {
      setActiveTab(1);
    }
    dispatch(propertyUsersAction.setSelectedProperty(row));
    setSelectedRow(row);
    setIsViewEditUserScreenOpen(!isViewEditUserScreenOpen);
  };

  const handleBackToUserListPage = () => {
    setIsViewEditUserScreenOpen(false);
    setSelectedRow(null);
    dispatch(propertyUsersAction.setSelectedProperty({}));
    getPropertyUserListingData(true);
  };

  // for sorting
  const handleSorting = (column) => {
    setDirectionFlow(!directionFlow);
    console.log(column, "Sorting function called", payload);
    console.log("Sorting flow", directionFlow);
    setPayload((prevState) => ({
      ...prevState,
      order_by_column: column,
      sorting_order: !directionFlow ? "desc" : "asc",
    }));
  };

  useEffect(() => {
    getPropertyUserListingData();
  }, [dispatch, getPropertyUserListingData]);

  useEffect(() => {
    if (getObjLength(propertyUsersList)) {
      if (propertyUsersList?.success) {
        // setLeadsData(propertyUsersList?.data);
      }
      setEditAlert(false);
      if (
        redirect_from === ROLES.provider ||
        redirect_from === ROLES.countyRecorder ||
        redirect_from === "logs"
      ) {
        let data = propertyUsersList?.data?.find(
          (i) => i.user_id === parseInt(user_id)
        );
        setSelectedRow(data);
        const newUrl = window.location.origin + window.location.pathname;
        handleViewEditUser(
          data,
          redirect_from === (ROLES.provider || ROLES.countyRecorder)
        );
        window.history.replaceState({}, document.title, newUrl);
        console.log("dat==>", data);
      }
    }
  }, [propertyUsersList]);

  useEffect(() => {
    if (getObjLength(propertyUsersData)) {
      setAlertMsg(true);
      if (propertyUsersData?.success) {
        setSuccessMsg(propertyUsersData?.message);
        setErrMsg("");
      } else {
        setSuccessMsg("");
        setErrMsg(propertyUsersData?.data?.message);
      }
    }
  }, [dispatch, propertyUsersData]);

  useEffect(() => {
    clearLocalStorageData();
  }, []);

  useEffect(() => {
    console.log(
      !decryptPayload(localStorage.getItem("roles"))?.includes(ROLES.provider),
      "1111->>",
      !decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.countyRecorder
      )
    );
    if (
      !decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.provider
      ) &&
      !decryptPayload(localStorage.getItem("roles"))?.includes(
        ROLES.countyRecorder
      )
    ) {
      dispatch(
        otherProvidersAction.domainListData({
          url: "admin/providers-list",
          data: {},
          token: true,
        })
      );
      dispatch(
        countyRecordersAction.registeredCountyList({
          url: `admin/counties`,
          data: {},
          token: true,
        })
      );
    }
  }, [dispatch]);
  let county_admin = decryptPayload(localStorage.getItem("roles"))?.includes(
    ROLES.countyRecorder
  );

  const handleCountyChange = (event, newValue) => {
    const selectedCounty = registeredCountyListData?.data.find(
      (county) => county.county_domain_name === newValue
    );
    if (getObjLength(selectedCounty)) {
      setValue(selectedCounty.id.toString());
      setDomainName();
    }
  };

  return (
    <>
      <>
        {isViewEditUserScreenOpen ? (
          <ViewEditUserPage
            selectedUser={selectedRow}
            backToUserListPageHandler={handleBackToUserListPage}
            activeTab={activeTab}
            offsetNum={
              getObjLength(payload)
                ? encryptPayload(JSON.stringify(payload))
                : null
            }
          />
        ) : (
          <Box>
            <Paper
              elevation={0}
              className=" d-flex justify-content-between mb-1"
              sx={{ background: "none" }}
            >
              <Box
                className="w-100"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 1,
                }}
              >
                <Typography variant="h6" className="card-title fw-600">
                  Property Users
                </Typography>
              </Box>
            </Paper>

            {/* Filtering Component Section */}
            <Box
              id="filter"
              onClick={() => setToggleFilterOptions(!toggleFilterOptions)}
            >
              <h3 className="filter-text">Filter your results</h3>
              <ArrowDropDownIcon
                className="filter-arrow"
                sx={{ ms: 1, fontSize: "20px" }}
              />
            </Box>

            {/* Filtering Options */}
            <Box
              sx={{
                overflow: "hidden",
                overflowX: "auto",
                mb: 1.5,
              }}
            >
              {toggleFilterOptions && (
                <>
                  {/* for mobile view */}
                  <Box className="d-block d-md-none">
                    <Grid
                      container
                      className="bg-white d-flex justify-content-around align-items-center px-4"
                      spacing={2}
                      pb={2}
                      pt={2}
                    >
                      <Grid item xs={6} sm={6} md={4} lg={2}>
                        <Typography className="search-text">
                          Search By
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={6} md={4} lg={2}>
                        <FormControl className="filter-select-one w-100">
                          <TextField
                            id="outlined-select-currency"
                            select
                            label="All Types"
                            name="type"
                            onChange={(event) => {
                              setAllTypes(event.target.value);
                              setJsonLastStep("");
                              setValue("");
                              setSelectedUserType("");

                              setSelectedDateRange(null);
                              setSelectedStatusType("");
                              if (event.target.value === "all") {
                                setSelectedDateType("created_at");
                              } else {
                                setSelectedDateType("");
                              }
                            }}
                            value={allTypes}
                          >
                            <MenuItem value={"all"}>All</MenuItem>
                            {columns.map(
                              (cell) =>
                                cell.id !== "no" &&
                                cell.id !== "created_by" &&
                                cell.id !== "status" &&
                                cell.id !== "notes" &&
                                cell.id !== "properties" &&
                                cell.id !== "docs" &&
                                cell.id !== "actions" && (
                                  <MenuItem key={cell.id} value={cell.id}>
                                    {cell.label}
                                  </MenuItem>
                                )
                            )}
                            <MenuItem value={"status"}>User Status</MenuItem>
                            {!decryptPayload(
                              localStorage.getItem("roles")
                            )?.includes(ROLES.provider) &&
                              !county_admin && (
                                <MenuItem value={"domain"}>Provider</MenuItem>
                              )}
                            {!county_admin && (
                              <MenuItem value={"county"}>County</MenuItem>
                            )}
                            <MenuItem value={"referral_user"}>
                              Referral User
                            </MenuItem>
                            <MenuItem value={"promo_code"}>Promo Code</MenuItem>
                            <MenuItem value={"json_last_step"}>
                              Last Step
                            </MenuItem>
                          </TextField>
                        </FormControl>
                      </Grid>
                      {allTypes !== "json_last_step" &&
                        allTypes !== "phone" &&
                        allTypes !== "created_at" &&
                        allTypes !== "county" &&
                        allTypes !== "updated_at" &&
                        allTypes !== "domain " &&
                        allTypes !== "type" && (
                          <Grid item xs={12} sm={12} md={4} lg={2}>
                            <FormControl
                              className="filter-search w-100"
                              variant="outlined"
                            >
                              <InputLabel>Enter search term</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Search />
                                  </InputAdornment>
                                }
                                label="Enter search term"
                                name="value"
                                onChange={(event) => {
                                  setValue(event.target.value);
                                }}
                                value={value}
                              />
                            </FormControl>
                          </Grid>
                        )}
                      {allTypes === "json_last_step" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Last Step"
                              value={jsonLastStep}
                              onChange={(e) => {
                                setValue(e.target.value);
                                setJsonLastStep(e.target.value);
                              }}
                            >
                              {jsonLastStepOptions.map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {allTypes === "county" && (
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="County List"
                              value={value}
                              onChange={(e) =>
                                setValue(e.target.value.toString())
                              }
                            >
                              {registeredCountyListData?.data?.map(
                                (county, key) => (
                                  <MenuItem value={county?.id}>
                                    {county?.county_domain_name}
                                  </MenuItem>
                                )
                              )}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {allTypes === "type" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="User Type"
                              value={selectedUserType}
                              onChange={(e) => {
                                setValue("");
                                setSelectedUserType(e.target.value);
                              }}
                            >
                              {filterUserType?.map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {allTypes === "domain" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Provider Name"
                              value={domainName}
                              onChange={(e) => {
                                setDomainName(e.target.value);
                              }}
                            >
                              {domainListDataList?.data?.map((data, key) => (
                                <MenuItem value={data.domain}>
                                  {data.domain || data?.custom_domain}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {allTypes === "phone" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <Box
                            className={`phone-number-input bg-white filter w-100`}
                          >
                            <PhoneInput
                              className="bg-white"
                              international={false}
                              placeholder="Phone Number"
                              value={value}
                              countries={["US", "IN"]}
                              defaultCountry={
                                COUNTRY_CODE === "+91" ? "IN" : "US"
                              }
                              countryOptionsOrder={["US", "IN"]}
                              addInternationalOption={false}
                              onChange={(event) => {
                                setValue(event);
                              }}
                            />
                          </Box>
                        </Grid>
                      )}
                      {allTypes === "all" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Status type"
                              value={selectedStatusType}
                              onChange={(e) => {
                                setSelectedStatusType(e.target.value);
                              }}
                            >
                              {[
                                { label: "Active", value: "active" },
                                { label: "Inactive", value: "inactive" },
                                { label: "Pending", value: "pending" },
                              ].map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {allTypes === "all" && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Date type"
                              value={selectedDateType}
                              onChange={(e) => {
                                setSelectedDateType(e.target.value);
                              }}
                            >
                              {[
                                { label: "Created At", value: "created_at" },
                                { label: "Updated At", value: "updated_at" },
                              ].map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}
                      {(allTypes === "all" ||
                        allTypes === "created_at" ||
                        allTypes === "updated_at") && (
                        <Grid item xs={12} sm={12} md={4} lg={2}>
                          <FormControl className="filter-search w-100">
                            <CustomDateRangePicker
                              rangeValue={selectedDateRange}
                              onChange={(values) => {
                                setSelectedDateRange(values);
                              }}
                              onClear={() => {
                                setSelectedDateRange(null);
                              }}
                            />
                          </FormControl>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <FormControl className="w-100">
                          <Button
                            type="submit"
                            onClick={filterOptions}
                            sx={mobileFilterResultStyle}
                          >
                            Filter Results
                          </Button>
                        </FormControl>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={2}
                        lg={2}
                        className="d-flex justify-content-center align-items-center"
                      >
                        <Button
                          type="reset"
                          onClick={resetData}
                          sx={mobileFilterResetBtnStyle}
                        >
                          Reset
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>

                  {/* for desktop view */}
                  <Card
                    className="d-none d-md-block filter-options-carddiv"
                    // id="filter-options-card"
                  >
                    <Grid container className="filter-options-box">
                      <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <div className="search-text mx-2">Search By</div>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="All Types"
                              name="type"
                              onChange={(event) => {
                                setAllTypes(event.target.value);
                                setValue("");
                                setJsonLastStep("");
                                setSelectedUserType("");
                                setSelectedDateRange(null);
                                setSelectedStatusType("");
                                if (event.target.value === "all") {
                                  setSelectedDateType("created_at");
                                } else {
                                  setSelectedDateType("");
                                }
                              }}
                              value={allTypes}
                            >
                              <MenuItem value={"all"}>All</MenuItem>
                              {columns.map(
                                (cell) =>
                                  cell.id !== "no" &&
                                  cell.id !== "created_by" &&
                                  cell.id !== "status" &&
                                  cell.id !== "notes" &&
                                  cell.id !== "properties" &&
                                  cell.id !== "docs" &&
                                  cell.id !== "actions" && (
                                    <MenuItem key={cell.id} value={cell.id}>
                                      {cell.label}
                                    </MenuItem>
                                  )
                              )}
                              <MenuItem value={"status"}>User Status</MenuItem>
                              {!decryptPayload(
                                localStorage.getItem("roles")
                              )?.includes(ROLES.provider) &&
                                !county_admin && (
                                  <MenuItem value={"domain"}>Provider</MenuItem>
                                )}
                              {!county_admin && (
                                <MenuItem value={"county"}>County</MenuItem>
                              )}
                              <MenuItem value={"referral_user"}>
                                Referral User
                              </MenuItem>
                              <MenuItem value={"promo_code"}>
                                Promo Code
                              </MenuItem>
                              <MenuItem value={"json_last_step"}>
                                Last Step
                              </MenuItem>
                            </TextField>
                          </FormControl>
                        </Stack>
                      </Grid>

                      {allTypes !== "json_last_step" &&
                        allTypes !== "phone" &&
                        allTypes !== "created_at" &&
                        allTypes !== "updated_at" &&
                        allTypes !== "county" &&
                        allTypes !== "status" &&
                        allTypes !== "domain" &&
                        allTypes !== "type" && (
                          <Grid item>
                            <FormControl
                              className="filter-search"
                              variant="outlined"
                            >
                              <InputLabel>Enter search term</InputLabel>
                              <OutlinedInput
                                id="outlined-adornment-password"
                                endAdornment={
                                  <InputAdornment position="end">
                                    <Search />
                                  </InputAdornment>
                                }
                                label="Enter search term"
                                name="value"
                                onChange={(event) => {
                                  setValue(event.target.value);
                                }}
                                value={value}
                              />
                            </FormControl>
                          </Grid>
                        )}

                      {allTypes === "county" && (
                        <Grid item xs={12} sm={12} md={2} lg={2}>
                          <FormControl className="filter-select-one w-100">
                            <Autocomplete
                              id="asynchronous-demo"
                              disableClearable
                              options={registeredCountyListData?.data.map(
                                (option) => option?.county_domain_name
                              )}
                              onChange={handleCountyChange}
                              renderInput={(params) => (
                                <TextField {...params} label="County List" />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      )}

                      {allTypes === "type" && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="User Type"
                              value={selectedUserType}
                              onChange={(e) => {
                                setValue("");
                                setDomainName();
                                setSelectedUserType(e.target.value);
                              }}
                            >
                              {filterUserType?.map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {allTypes === "domain" && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Provider Name"
                              value={domainName}
                              onChange={(e) => {
                                setDomainName(e.target.value);
                                setValue("");
                              }}
                            >
                              {domainListDataList?.data?.map((data, key) => (
                                <MenuItem
                                  value={data.domain || data?.custom_domain}
                                >
                                  {data.domain || data?.custom_domain}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {allTypes === "json_last_step" && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Last Step"
                              value={jsonLastStep}
                              onChange={(e) => {
                                setValue(e.target.value);
                                setJsonLastStep(e.target.value);
                              }}
                            >
                              {jsonLastStepOptions.map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {allTypes === "phone" && (
                        <Grid item>
                          <Box className={`phone-number-input bg-white filter`}>
                            <PhoneInput
                              className="bg-white"
                              international={false}
                              placeholder="Phone Number"
                              value={value}
                              countries={["US", "IN"]}
                              defaultCountry={
                                COUNTRY_CODE === "+91" ? "IN" : "US"
                              }
                              countryOptionsOrder={["US", "IN"]}
                              addInternationalOption={false}
                              onChange={(event) => {
                                setValue(event);
                              }}
                            />
                          </Box>
                        </Grid>
                      )}

                      {(allTypes === "all" || allTypes === "status") && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Status type"
                              value={selectedStatusType}
                              onChange={(e) => {
                                setSelectedStatusType(e.target.value);
                              }}
                            >
                              {[
                                { label: "Active", value: "active" },
                                { label: "Inactive", value: "inactive" },
                                { label: "Pending", value: "pending" },
                              ].map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {allTypes === "all" && (
                        <Grid item>
                          <FormControl className="filter-select-one">
                            <TextField
                              id="outlined-select-currency"
                              select
                              label="Date type"
                              value={selectedDateType}
                              onChange={(e) => {
                                setSelectedDateType(e.target.value);
                              }}
                            >
                              {[
                                { label: "Created At", value: "created_at" },
                                { label: "Updated At", value: "updated_at" },
                              ].map((data, key) => (
                                <MenuItem value={data.value}>
                                  {data.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </FormControl>
                        </Grid>
                      )}

                      {(allTypes === "all" ||
                        allTypes === "created_at" ||
                        allTypes === "updated_at") && (
                        <Grid item>
                          <FormControl
                            className="filter-search position-relative"
                            variant="outlined"
                          >
                            <CustomDateRangePicker
                              rangeValue={selectedDateRange}
                              onChange={(values) => {
                                setSelectedDateRange(values);
                              }}
                              onClear={() => {
                                setSelectedDateRange(null);
                              }}
                            />
                          </FormControl>
                        </Grid>
                      )}

                      <Grid item>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <FormControl>
                            <Button
                              type="submit"
                              onClick={filterOptions}
                              className="filter-button"
                            >
                              Filter Results
                            </Button>
                          </FormControl>
                          <Button
                            type="reset"
                            onClick={() => {
                              resetData();
                            }}
                            className="reset-filters"
                          >
                            Reset
                          </Button>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Card>
                </>
              )}
            </Box>
            <PropertyUsersListTable
              openPopup={editAlert}
              columns={columns}
              resetStates={resetFilterStates}
              page={page}
              setPage={setPage}
              pageChangeHandler={pageChangeHandler}
              callListingAPI={() => {
                getPropertyUserListingData();
              }}
              handleViewEditUser={handleViewEditUser}
              directionFlow={directionFlow}
              handleSorting={handleSorting}
              selectedColumn={selectedColumn}
              setSelectedColumn={setSelectedColumn}
              callUserListingAPI={() => {
                getPropertyUserListingData();
              }}
              offsetNum={
                getObjLength(payload)
                  ? encryptPayload(JSON.stringify(payload))
                  : null
              }
            />
          </Box>
        )}
      </>

      {alertMsg && getObjLength(propertyUsersData) && (
        <>
          {getObjLength(propertyUsersData) && propertyUsersData?.success ? (
            <AlertDialog
              type="Success"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                onSuccess();
              }}
              mesage={successMsg}
              footer={false}
            />
          ) : (
            <AlertDialog
              type="Error"
              openPopup={alertMsg}
              onClose={true}
              onCloseFun={() => {
                setAlertMsg(false);
                dispatch(propertyUsersAction.clearData({}));
              }}
              mesage={errMsg}
              footer={false}
            />
          )}
        </>
      )}

      <Loader open={propertyUsersLoader || historyNoteLoader} />
    </>
  );
};
export default PropertyUsersIndex;
