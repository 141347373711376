import React, { useEffect, useState } from "react";

import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { Box, Button, Paper, Typography } from "@mui/material";

import { Loader } from "../../common/loader";
import DialogBox from "../../common/DialogBox";
import { getObjLength } from "../../helpers/utils";
import RecordingFeesList from "./RecordingFeesList";
import RecordingFeesModal from "./RecordingFeesModal";
import { userSigupAction } from "../../../redux/slices/user/userSignUpSlice";
import { countyRecordersAction } from "../../../redux/slices/admin/county-recorder/countyRecorderSlice";

const StatewiseRecordingFees = () => {
  const dispatch = useDispatch();
  const [recordingFees, setRecordingFees] = useState(true);
  const [plansDetails, setplansDetails] = useState(true);
  const [personalDetails, setPersonalDetails] = useState(true);
  const [feesList, setFeesList] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [payload, setPayload] = useState({});

  const stateData = useSelector((state) => state.signUpUser.stateList);
  const { recordingFeesLoader, createRecordingFees, recordingFeesList } =
    useSelector((state) => state.countyRecorders);

  const columns = [
    {
      key: 1,
      id: "no",
      disablePadding: true,
      label: "No.",
      minWidth: 50,
    },
    {
      key: 2,
      id: "state_name",
      disablePadding: true,
      label: "State",
      minWidth: 120,
    },
    {
      key: 3,
      id: "recordingfees",
      disablePadding: true,
      label: "Recording Fees",
      minWidth: 120,
    },
    {
      key: 4,
      id: "created_at",
      disablePadding: true,
      label: "Created",
      sorting: true,
      minWidth: 115,
    },
    {
      key: 5,
      id: "updated_at",
      disablePadding: true,
      label: "Modified",
      sorting: true,
      minWidth: 115,
    },
    {
      key: 6,
      id: "status",
      disablePadding: true,
      label: "Status",
      minWidth: 120,
    },
    {
      key: 7,
      id: "setting",
      disablePadding: true,
      label: "Actions",
      minWidth: 70,
    },
  ];

  const fieldsValidations = (data) => {
    return Yup.object().shape({
      state_id: data?.personalDetails
        ? Yup.string("Please select the state").required("State is required")
        : Yup.string(),

      amount:
        data?.plansDetails && data?.recordingFees
          ? Yup.number("Please enter your Recording  fees.")
              .max(1000, "Too Long!")
              .test(
                "not-allowed-chars",
                "Please enter postive amount for Recording fees",
                (value) => {
                  // Custom validation to check for the presence of '-' and 'e'
                  return (
                    value === undefined ||
                    (typeof value === "number" &&
                      `${value}`.indexOf("-") === -1 &&
                      `${value}`.indexOf("e") === -1)
                  );
                }
              )
              .required("Recording  fees is required")
          : Yup.number(),
    });
  };

  const getRecordingfeesListingData = () => {
    console.log("called......1");
    dispatch(
      countyRecordersAction.recordingFeesListData({
        url: `admin/recording-charge/list`,
        data: {},
        token: true,
      })
    );
  };
  const handleEditModal = () => {
    setOpenPopup(true);
  };
  useEffect(() => {
    if (getObjLength(recordingFeesList)) {
      if (recordingFeesList?.success) {
        setFeesList(recordingFeesList?.data);
      }
    }
    console.log("calledddddddddddddddd", feesList);
  }, [recordingFeesLoader]);

  useEffect(() => {
    // getRecordingfeesListingData();
    dispatch(userSigupAction.getState({ url: "states", data: {} }));
    console.log("recording fees list", recordingFeesList);
  }, [dispatch]);

  const validationSchema = fieldsValidations({
    personalDetails,
    recordingFees,
    plansDetails,
  });

  const handleSubmit = (values, errors) => {
    let payloadData = {
      amount: values.amount,
    };
    if (selectedRow && selectedRow?.id) {
      // handle edit dispatch
      dispatch(
        countyRecordersAction.createStatewiseRecordingFeesData({
          url: `admin/recording-charge/${selectedRow.id}/update`,
          data: payloadData,
          isFile: true,
          token: true,
        })
      );
      setSelectedRow(null);
    } else {
      // handle create dispatch
      dispatch(
        countyRecordersAction.createStatewiseRecordingFeesData({
          url: `admin/recording-charge/create/${values.state_id}`,
          data: payloadData,
          isFile: true,
          token: true,
        })
      );
    }
  };

  return (
    <>
      <Box>
        <Paper
          elevation={0}
          className=" d-flex justify-content-between"
          sx={{ background: "none" }}
        >
          <Box className="" sx={{ mb: 1 }}>
            <Typography variant="h6" className="card-title fw-600">
              Statewise Recording Fees
            </Typography>
          </Box>
          <Box className="">
            <Button
              className="success-btn d-none d-sm-block"
              variant="contained"
              onClick={() => {
                setOpenPopup(true);
              }}
            >
              Add Recording Fees
            </Button>
            <Button
              className="success-btn d-flex d-sm-none"
              variant="outlined"
              onClick={() => {
                setOpenPopup(true);
              }}
              // startIcon={<SendIcon />}
              sx={{ color: "#FFF" }}
            >
              Add Fees
            </Button>
          </Box>
        </Paper>
        <RecordingFeesList
          // getRecordingfeesListingData={getRecordingfeesListingData}
          setSelectedRow={setSelectedRow}
          selectedRow={selectedRow}
          handleEditModal={handleEditModal}
          recordingFeesList={recordingFeesList}
          rowsData={feesList}
          columns={columns}
          payload={payload}
          setPayload={setPayload}
        />
      </Box>

      {/* Recording Fees Modal */}
      {openPopup && (
        <DialogBox
          width={getObjLength(createRecordingFees) ? "sm" : "md"}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          title={
            !getObjLength(createRecordingFees) &&
            (selectedRow ? "Edit Recording Fees" : "Add Recording Fees")
          }
          onClose={() => {
            setOpenPopup(false);
            setSelectedRow(null);
          }}
          dividers={true}
          contentClassName={getObjLength(createRecordingFees) && "bg-white"}
          // title={!getObjLength(addLeadsData) && modalTitle()}
          content={
            <RecordingFeesModal
              stateData={stateData}
              validationSchema={validationSchema}
              setSelectedRow={setSelectedRow}
              selectedRow={selectedRow}
              handleSubmit={handleSubmit}
              // getRecordingfeesListingData={getRecordingfeesListingData}
              setOpenPopup={setOpenPopup}
              payload={payload}
            />
          }
          // footer={modalFooter()}
        />
      )}
      <Loader open={recordingFeesLoader} />
    </>
  );
};

export default StatewiseRecordingFees;
