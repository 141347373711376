import { useState } from "react";
import { useCallback } from "react";
import React, { useEffect } from "react";
import CloseIcon from "@mui/icons-material/Close";
import LockIcon from "@mui/icons-material/Lock";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Box } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {
  Button,
  Dialog,
  MobileStepper,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import { Loader } from "../common/loader";
import DialogBox from "../common/DialogBox";
import { ROLES } from "../helpers/constants";
import AddPropertyPopup from "./add-property";
import AlertDialog from "../common/AlertDialog";
import OrderDocuments from "./OrderDocuments/index";
import PaymentFailed from "./Dashboard/PaymentFailed";
import { getObjLength, reactPixelEventOnlyForCustomer } from "../helpers/utils";
import AddSubscriptionModal from "../common/AddSubscriptionModal";
import MapSection from "../common/userPropertiesSections/MapSection";
import CancelSubscriptionModal from "../common/CancelSubscriptionModal";
import { userSigupAction } from "../../redux/slices/user/userSignUpSlice";
import { userProfileAction } from "../../redux/slices/user/userProfileSlice";
import { propertiesAction } from "../../redux/slices/properties/propertiesSlice";
import UserPropertyViewDetailsModal from "../common/UserPropertyViewDetailsModal";
import TitalChainSection from "../common/userPropertiesSections/TitalChainSection";
import SecurityQuestionsVerification from "../admin/AdminDashboard/SecurityQuestionsVerification";

import "../../styles/properties.scss";
import DrawerFromBottom from "../common/DrawerFromBottom";
import DownloadFile from "./OrderDocuments/DownloadFile";

export const PaperStyle = {
  p: 3,
  boxShadow: "0px 2px 2px rgb(52 75 91 / 6%)",
  borderRadius: "4px",
};

const PER_PAGE = 3;

const Properties = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const [propertiesLoader, setPropertiesLoader] = useState(false);
  const [maxSteps, setMaxSteps] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [docActivStep, setDocActivStep] = useState(0);
  const [scroll, setScroll] = React.useState("paper");
  const [resetState, setResetState] = useState(false);
  const [showDownloadPage, setShowDownloadPage] = useState(false);
  const [cancelSubscriptionModalOpen, setCancelSubscriptionModalOpen] =
    useState(false);
  const [isAddSubscriptionModalOpen, setIsAddSubscriptionModalOpen] =
    useState(false);
  const [isViewPropertyDetailModalOpen, setIsViewPropertyDetailModalOpen] =
    useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [securityQuestionsPopup, setSecurityQuestionsPopup] = useState(false);
  const [page, setPage] = useState(1);
  const [offsetVal, setOffsetVal] = useState(0); // for restrict the payment-status API calls
  const [isMapDisplay, setIsMapDisplay] = useState(true); // for restrict MAP API calls when add property popup is active

  // store data
  const {
    propertiesData,
    propertiesCount,
    propertiesLoader,
    cancelSubscriptionDetails,
    cancelSubscriptionLoader,
  } = useSelector((state) => state.properties);
  const { data: userData } = useSelector(
    (state) => state?.userProfile?.userProfileDetails
  );
  const [isPaymentDonePopup, setIsPaymentDonePopup] = useState(false);
  const {
    cardDetails,
    loggedInEmail,
    loginAsSpouse: isSecondaryUser,
    paymnetDoneDetails,
    isPaymentPending,
    parent_id,
  } = useSelector((state) => state?.userProfile);

  // dispatch action for getting property list
  const getPropertyList = useCallback(
    (page = 1) => {
      // setPropertiesLoader(true);
      let url = `customer/properties-v1`;
      if (isSecondaryUser) {
        url = `customer/properties-v1/${parent_id}`;
      }
      localStorage.removeItem("planDetails");
      localStorage.removeItem("subscriptionScreen");
      localStorage.removeItem("userSignupDetails");
      sessionStorage.removeItem("userSignupDetails");
      localStorage.removeItem("currentStep");
      localStorage.removeItem("user_Id");
      setOffsetVal((page - 1) * PER_PAGE);
      dispatch(
        propertiesAction.fetchPropertyList({
          url: url,
          data: {
            limit: PER_PAGE,
            offset: (page - 1) * PER_PAGE,
          },
          token: true,
        })
      );
    },
    [dispatch, isSecondaryUser, parent_id]
  );

  // handle open view property modal
  const handleOpenViewPropertyDetailModal = () => {
    setIsViewPropertyDetailModalOpen(true);
  };

  // handle close view property modal
  const handleCloseViewPropertyDetailModal = () => {
    setIsViewPropertyDetailModalOpen(false);
  };

  // handle open cancel subscription modal
  const handleOpenAddSubscriptionModal = () => {
    setIsAddSubscriptionModalOpen(true);
  };

  // handle close cancel subscription modal
  const handleCloseAddSubscriptionModal = () => {
    setIsAddSubscriptionModalOpen(false);
    setSelectedProperty(null);
  };

  // handle open cancel subscription modal
  const handleOpenCancelSubscriptionModal = () => {
    setCancelSubscriptionModalOpen(true);
  };

  // handle close cancel subscription modal
  const handleCloseCancelSubscriptionModal = () => {
    console.log(" close cancel subscription modal");
    setCancelSubscriptionModalOpen(false);
  };

  // handle cancel subscription modal
  const handleCancelSubscriptionBtn = (type, card_id) => {
    // setCancelSubscriptionModalOpen(false);
    let data = { type: type, card_id };
    dispatch(
      propertiesAction.cancelSubscription({
        url:
          "customer/cancel-subscription/" +
          propertiesData[activeStep]?.subscription?.id,
        data: data,
        token: true,
        propertyAddress: propertiesData[activeStep]?.address,
      })
    );
  };

  // move 1 step further
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    dispatch(propertiesAction.clearOrderDoc());
    if (
      activeStep + 1 === propertiesData?.length &&
      propertiesData?.length < propertiesCount
    ) {
      setPage((prevState) => prevState + 1);
      getPropertyList(page + 1);
    }
  };

  // move 1 step backward
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    dispatch(propertiesAction.clearOrderDoc());
  };

  // handle security questions API
  const handleSecurityPropertyQuestions = (data) => {
    console.log("==?>", data);
    setSecurityQuestionsPopup(true);
    if (data && data?.id) {
      dispatch(
        propertiesAction.securityPropertiesQueDetails({
          url: `customer/generate-questions/${data?.id}`,
          data: {},
          token: true,
        })
      );
    }
  };

  // for react pixel events
  useEffect(() => {
    if (loggedInEmail?.length > 0) {
      reactPixelEventOnlyForCustomer("Properties Page", {
        email: loggedInEmail,
      });
    }
  }, [loggedInEmail]);

  // to get property list for logged in user
  useEffect(() => {
    if (!userData?.role?.includes(ROLES.secondaryUser)) {
      // dispatch(propertiesAction.resetProperties());
      dispatch(
        userSigupAction.getSubscriptionPlan({
          url: "get-plans",
          data: {},
          type: "post",
        })
      );
      dispatch(
        userProfileAction.userCardDetails({
          url: "customer/card/list",
          data: {},
          token: true,
        })
      );
    }
    dispatch(
      propertiesAction.getDocumentTypes({
        url: "customer/properties/document-types",
        data: "",
        token: true,
      })
    );
  }, [dispatch, userData?.role]);

  useEffect(() => {
    // fetch property list
    getPropertyList(1);
  }, [getPropertyList]);

  useEffect(() => {
    setMaxSteps(propertiesCount);
    if (getObjLength(propertiesData)) {
      if (
        parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
        !(offsetVal > 0)
      ) {
        dispatch(
          userProfileAction.paymnetDoneDetails({
            url: "payment-status",
            data: {},
            token: true,
          })
        );
      }
    }
  }, [dispatch, propertiesCount, propertiesData, offsetVal]);
  console.log(docActivStep);
  useEffect(() => {
    console.log("paymnetDoneDetails==>", paymnetDoneDetails);
    if (getObjLength(paymnetDoneDetails)) {
      console.log("isPaymentPending==>", isPaymentPending);
      if (isPaymentPending) {
        //open modal
        setIsPaymentDonePopup(false);
      } else {
        setIsPaymentDonePopup(true);
      }
    }
  }, [dispatch, isPaymentPending, paymnetDoneDetails]);

  const handleClickOpen = (scrollType) => () => {
    setOpen(true);
    setScroll(scrollType);
    setResetState(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDocActivStep(0);
    setResetState(false);
    setTimeout(() => dispatch(propertiesAction.clearOrderDoc()), 1500);
  };

  return (
    <>
      <Box className="properties-wrapper">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h6" className="title">
              My properties (
              {propertiesData?.length > 0
                ? `${activeStep + 1}/${propertiesCount}`
                : `0/0`}
              )
            </Typography>
            <MobileStepper
              variant="dots"
              steps={maxSteps ? maxSteps : 0}
              position="static"
              activeStep={activeStep}
              sx={{ backgroundColor: "transparent" }}
              nextButton={
                <Button
                  onClick={handleNext}
                  disabled={
                    !propertiesCount || activeStep + 1 >= propertiesCount
                  }
                >
                  <ArrowForwardIcon />
                </Button>
              }
              backButton={
                <Button
                  onClick={handleBack}
                  disabled={!propertiesCount || activeStep === 0}
                >
                  <ArrowBackIcon />
                </Button>
              }
            />
          </Stack>

          {/* <Button
            variant="contained"
            className="addBtn d-none d-lg-block"
            onClick={(e) => {
              e.preventDefault();
              handleOpenAddPropertyModal();
            }}
          >
            Add property
          </Button> */}
          {!isSecondaryUser && (
            <AddPropertyPopup
              classes={`addBtn ${
                propertiesData?.length > 0
                  ? "d-none d-lg-block"
                  : "d-none d-sm-block"
              }`}
              getPropertyList={() => getPropertyList(page)}
              isUserCreateProperty={true}
              setIsMapDisplay={setIsMapDisplay}
              callListingAPI={false}
            />
          )}
        </Box>
        {/* if there are no properties and user open screen in mobile device */}
        {!isSecondaryUser && !propertiesData?.length > 0 && (
          <AddPropertyPopup
            classes={"addBtn w-100 d-block d-sm-none mt-2"}
            getPropertyList={() => getPropertyList(page)}
            isUserCreateProperty={true}
            setIsMapDisplay={setIsMapDisplay}
            callListingAPI={false}
          />
        )}
        {propertiesData?.length > 0 ? (
          <Box className="propertyBox_wrapper">
            <>
              <Paper className="mainPaper" sx={PaperStyle}>
                <MapSection
                  propertiesData={propertiesData}
                  activeStep={activeStep}
                  handleOpenViewPropertyDetailModal={
                    handleOpenViewPropertyDetailModal
                  }
                  isSecondaryUser={isSecondaryUser}
                  handleOpenCancelSubscriptionModal={
                    handleOpenCancelSubscriptionModal
                  }
                  setOpenAlertModal={setOpenAlertModal}
                  setSelectedProperty={setSelectedProperty}
                  handleOpenAddSubscriptionModal={
                    handleOpenAddSubscriptionModal
                  }
                  cardDetails={cardDetails}
                  getPropertyList={() =>
                    getPropertyList(parseInt(activeStep / 3) + 1)
                  }
                  callPaginationAPI={() => getPropertyList(page)}
                  isMapDisplay={isMapDisplay}
                  setIsMapDisplay={setIsMapDisplay}
                />
                <Paper className="gridpaper title-chain" elevation={0}>
                  <TitalChainSection
                    isSecondaryUser={isSecondaryUser}
                    activeStep={activeStep}
                    handleClickOpen={handleClickOpen}
                    propertiesData={propertiesData}
                    showRequestDocumentBtn={
                      propertiesData[activeStep]?.is_owner ? true : false
                    }
                  />
                </Paper>
              </Paper>
            </>
          </Box>
        ) : (
          ""
        )}
      </Box>

      {/* for Order Doc */}
      {/* <Dialog
        fullWidth={true}
        maxWidth="md"
        // sx={DialogStyle}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        className={`dialogWrapper step${docActivStep} ${docActivStep !== 2? 'zoom-modal-wrapper': ''}`}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      > */}
      {(open || showDownloadPage) && (
        <OrderDocuments
          showDrawer={open}
          setDocActivStep={setDocActivStep}
          resetStates={resetState}
          propertyId={propertiesData?.[activeStep]?.id}
          propertyData={propertiesData?.[activeStep]}
          closeModal={setOpen}
          onClose={handleClose}
          user_id={userData?.user_id}
          showDownloadPage={showDownloadPage}
          setShowDownloadPage={setShowDownloadPage}
        />
      )}

      {/* {showDownloadPage && (
        <DialogBox
          width="md"
          style={{backgroundColor:"#f6f9fc"}}
          contentClassName={""}
          openPopup={showDownloadPage}
          setOpenPopup={setShowDownloadPage}
          onClose={() => {
            setShowDownloadPage(false);
            // dispatch(propertyUsersAction.clearDeleteCardData({}));
          }}
          content={
            <DownloadFile onClose={()=>{setShowDownloadPage(false);
              handleClose();
            }} />
          }
        />
      )} */}
      {/* </Dialog> */}
      {/* view user property detail modal */}
      <UserPropertyViewDetailsModal
        open={isViewPropertyDetailModalOpen}
        // open={false}
        handleClose={handleCloseViewPropertyDetailModal}
        selectedProperty={propertiesData?.[activeStep]}
      />
      {/* cancel subscription modal */}
      <CancelSubscriptionModal
        open={cancelSubscriptionModalOpen}
        cancelLoader={cancelSubscriptionLoader}
        isCancelSubscriptionSuccess={cancelSubscriptionDetails?.success}
        successMessage={cancelSubscriptionDetails?.message}
        errorMessage={cancelSubscriptionDetails?.data?.message}
        handleClose={handleCloseCancelSubscriptionModal}
        handleCancelSubscriptionBtn={handleCancelSubscriptionBtn}
        endDate={propertiesData[activeStep]?.subscription?.end_at}
        cancelSubscriptionDetails={cancelSubscriptionDetails}
        selectedProperty={propertiesData?.[activeStep]}
        getPropertyList={() => getPropertyList(parseInt(activeStep / 3) + 1)}
      />

      {/* add subscription modal */}
      {isAddSubscriptionModalOpen && (
        <AddSubscriptionModal
          open={isAddSubscriptionModalOpen}
          selectedProperty={selectedProperty}
          closeModalHandler={handleCloseAddSubscriptionModal}
          //adding promocode
          addPromoCode={true}
          getPropertyList={() => getPropertyList(parseInt(activeStep / 3) + 1)}
        />
      )}

      {openAlertModal && !cardDetails?.data?.length > 0 && (
        <>
          <AlertDialog
            type="Warning"
            openPopup={openAlertModal}
            onClose={true}
            onCloseFun={() => {
              setOpenAlertModal(false);
            }}
            buttons={
              <>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  sx={{
                    backgroundColor: "#ffffff !important",
                    color: "black !important",
                    border: "1px #9cb8a6 solid !important",
                  }}
                  onClick={() => {
                    setOpenAlertModal(false);
                  }}
                >
                  ok, close
                </Button>
                <Button
                  className="popup_Btn success_popup_btn mx-2"
                  onClick={() => {
                    setOpenAlertModal(false);
                    navigate("/user-profile", {
                      replace: true,
                      state: { isAddPaymentModal: true },
                    });
                  }}
                >
                  ok, let's go
                </Button>
              </>
            }
            mesage={
              "You don't have any cards added. Please add the card first from the user profile page."
            }
            footer={false}
          />
        </>
      )}

      {/* Add security Questions Modal */}
      {securityQuestionsPopup && (
        <DialogBox
          width="lg"
          contentClassName={"bg-white"}
          openPopup={securityQuestionsPopup}
          setOpenPopup={setSecurityQuestionsPopup}
          onClose={() => {
            setSecurityQuestionsPopup(false);
            // dispatch(propertyUsersAction.clearDeleteCardData({}));
          }}
          content={
            <SecurityQuestionsVerification
              selectedData={selectedProperty}
              callListingAPI={() => getPropertyList(page)}
              closePopup={setSecurityQuestionsPopup}
              uid={selectedProperty?.owner_id}
            />
          }
        />
      )}

      {/* Paymnet failed Popup */}

      {parseInt(localStorage.getItem("paymentPopup")) <= 2 &&
        isPaymentDonePopup && (
          <PaymentFailed
            isPaymentDonePopup={isPaymentDonePopup}
            setIsPaymentDonePopup={setIsPaymentDonePopup}
            callListAPI={true}
            apiCall={() => getPropertyList(page)}
          />
        )}

      <Loader open={propertiesLoader} />
    </>
  );
};

export default Properties;
